import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  background-color: #f6ebc1;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  p {
    font-size: 0.75rem;
    color: #000;
    text-align: center;
    letter-spacing: -0.15px;
    justify-content: center;
    line-height: 1;
    padding: 0.75rem;

    a {
      text-decoration: underline;
      color: #000;
      margin-left: 0.25rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .newBanner {
    color: black;
    padding: 3px 6px;
    background-color: #04e2c1;
    border-radius: 3px;
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 1;
    margin-right: 0.25rem;
    text-transform: uppercase;
    letter-spacing: -0.2px;
    display: inline-block;
    text-align: center;
  }
`

const Ele = () => {
  return (
    <Box>
      <p>
        <span className="newBanner">New</span> Tulumbe Morocco: One-click trip
        planning & AutoSaving for a stress-free AFCON experience
        <a href="https://learn.myxeno.com/articles/xeno-launches-tulumbe-morocco---a-stress-free-way-to-save-for-afcon-2025">
          Learn More
        </a>
      </p>
    </Box>
  )
}
export default Ele
